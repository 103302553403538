import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [

  {
    title: 'Dashboard',
    icon: 'nb-home',
    link: '/admin/dashboard',
    
  },
  {
    title: 'User Management',
    icon: 'nb-person',
    link: '/admin/users',
    
    children: [
      // {
      //   title: 'Add',
      //   link: '/admin/users/add',
      // },
      {
        title: 'View',
        link: '/admin/users/view',
      },
    ],
    hidden: false,
  },
  {
    title: 'Business Management',
    icon: 'nb-person',
    link: '/admin/business-users',
    
    children: [
      // {
      //   title: 'Add',
      //   link: '/admin/business-users/add',
      // },
      {
        title: 'View',
        link: '/admin/business-users/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'Category Management',
    icon: 'nb-person',
    link: '/admin/category',
    
    children: [
      {
        title: 'Add',
        link: '/admin/category/add',
      },
      {
        title: 'View',
        link: '/admin/category/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'Coupon Management',
    icon: 'nb-person',
    link: '/admin/coupons',
    
    children: [
      {
        title: 'View',
        link: '/admin/coupons/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'Payment Management',
    icon: 'nb-person',
    link: '/admin/payment',
    
    children: [
      {
        title: 'View',
        link: '/admin/payment/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'Product List',
    icon: 'nb-person',
    link: '/admin/product',    
    children: [
      {
        title: 'View',
        link: '/admin/product/view',
      },
    ],
    hidden: false,
  } ,
  {
  
    title: 'Tax & Service charges',
    icon: 'nb-person',
    link: '/admin/adminshare',
    children: [
        {
            title: 'View',
            link: '/admin/adminshare/view',
        },
      //   {
      //     title: 'Add',
      //     link: '/admin/adminshare/add',
      // }
    ],
    hidden: false,
  },
  // {
  //   title: 'Offer Management',
  //   icon: 'nb-person',
  //   link: '/admin/offers',
    
  //   children: [
  //     {
  //       title: 'View',
  //       link: '/admin/offers/view',
  //     },
  //   ],
  //   hidden: false,
  // } ,
//   {
//     title: 'CSM',
//     icon: 'nb-person',
//     link: '/admin/csm',
//     children: [
//         {
//             title: 'View',
//             link: '/admin/csm/view',
//         },
//         {
//           title: 'Add',
//           link: '/admin/csm/add',
//       }
//     ],
//     hidden: false,
// },
  {
    title: 'Wallet Management',
    icon: 'nb-person',
    link: '/admin/wallet',
    
    children: [
      {
        title: 'View',
        link: '/admin/wallet/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'Offer Reports Management',
    icon: 'nb-person',
    link: '/admin/report',
    
    children: [
      {
        title: 'View',
        link: '/admin/report/view',
      },
    ],
    hidden: false,
  } ,
  {
    title: 'FAQs',
    icon: 'nb-person',
    link: '/admin/faqs',
    children: [
      {
        title: 'Add',
        link: '/admin/faqs/add',
      },
        {
            title: 'View',
            link: '/admin/faqs/view',
        },
      ],
      hidden: false,
  },

  {
    title: 'Blogs',
    icon: 'nb-person',
    link: '/admin/blogs',
    children: [
      {
        title: 'Add',
        link: '/admin/blogs/add',
      },
        {
            title: 'View',
            link: '/admin/blogs/view',
        },
      ],
      hidden: false,
  },

{
  title: 'Contact Us',
  icon: 'nb-person',
  link: '/admin/contact-us',
  children: [
      {
          title: 'View',
          link: '/admin/contact-us/view',
      },
    ],
    hidden: false,
  },
  {
    title: 'CMS Pages',
    icon: 'nb-gear',
    link: '/admin/cms',
    children: [      
      {
        title: 'View',
        link: '/admin/cms/view',
      },
    ],
  },
  {
    title: 'Notification Management',
    icon: 'nb-person',
    link: '/admin/notification',
    children: [
        {
            title: 'View',
            link: '/admin/notification/view',
        },
        {
          title: 'Add',
          link: '/admin/notification/add',
      }
    ],
    hidden: false,
    },
  
];

